import * as XLSX from 'xlsx'
import { formatarData, formatarParaNumber, formatarValorReais, formatarValorPorcentagem } from '@/libs/utils'

const createSheet = (tableData: any[]) => {
  // cria um objeto de planilha vazio
  const workbook = XLSX.utils.book_new()
  // cria uma planilha vazia e adiciona ao objeto de planilha
  const worksheet = XLSX.utils.aoa_to_sheet([[]])
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório Geral')

  // adiciona dados da tabela à planilha
  XLSX.utils.sheet_add_json(worksheet, tableData, { origin: -1 })

  // salvar o arquivo XLSX
  XLSX.writeFile(workbook, 'relatorio-de-contratos.xlsx')
}

export const asDefault = () => {
  return {
    convertToExcelFormat: (item, formatarData) => {
      return {
        'CRIADO EM': item.createdAt ? formatarData(item.createdAt) : '',
        CONTRATO: item.codigo ?? '',
        'NOME DO TOMADOR': item.proposta.segurado.tomador.nomeCompleto ?? '',
        MATRIULA: item.proposta.segurado.matricula ?? '',
        'VALOR DO CONTRATO': formatarValorReais(item.proposta.valorComIOF) ?? '',
        TAXAS: formatarValorPorcentagem(item.taxas) ?? '',
        'VALOR DA PARCELA': formatarValorReais(item.valorParcela) ?? '',
        'Nº DE PARCELAS': item.numeroParcelas ?? '',
        'DATA DE INÍCIO': item.dtInicio ? formatarData(item.dtInicio) : '',
        STATUS: item.statusFinanceiro,
        ORGÃO: item.proposta.segurado.situacaoPrevidenciaria,
        //OBSERVAÇÃO: item.observacao ?? '',
      }
    },
    export: (items) => {
      const situationItems = items.map((item) => asDefault().convertToExcelFormat(item, formatarData))
      createSheet(situationItems)
    },
  }
}
