<template>
  <div class="cardRelatorio">
    <b-row>
      <b-col cols="2">
        <b-card-header
          header="Simulador Proposta"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.Proposta" @change="marcarDesmarcarHandler"> Acessar </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.PropostaConsulta">Consultar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.PropostaCriarNovo">Criar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.PropostaGerarContrato">Gerar Contrato</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.PropostaAprovarProposta">Aprovar Proposta</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-1" @click.prevent="authESalve">
          Salvar
        </b-button>
      </b-col>
    </b-row>
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </div>
</template>

<script>
  import {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BButton,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BCardText,
  } from 'bootstrap-vue'
  import useJwt from '@/auth/jwt/useJwt'
  import Ripple from 'vue-ripple-directive'
  import updateUserData from '@/@core/auth/utils/Update-User-Data'

  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'

  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      BCard,
      BTable,
      BCardBody,
      BCardTitle,
      BCardSubTitle,
      BFormCheckbox,
      BButton,
      BForm,
      BRow,
      BCol,
      BFormGroup,
      BCardText,
      AutenticaSenha,
      ToastificationContent,
      updateUserData,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
        updateUserData,
      }
    },
    methods: {
      CSimulador() {
        const value = this.optionsLocal.Proposta
        this.optionsLocal = {
          ...this.optionsLocal,
          PropostaConsulta: value,
          PropostaCriarNovo: value,
          PropostaGerarContrato: value,
          PropostaAprovarProposta: value,
        }
      },
      authESalve() {
        this.optionsLocal.mode = 'authESalve'
        this.$refs['modal-auth-senha'].show()
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.optionsLocal.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
      salvar() {
        if (this.optionsLocal.id == undefined) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erro: O instituto a ser configurado não está sendo identificado, reeinicie o sistema. ',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
        } else {
          const aux = {
            ...this.optionsLocal,
          }
          useJwt
            .update(`grupo-acesso/putGrupoSimulador`, this.optionsLocal.id, aux)
            .then((response) => {
              this.$swal({
                title: 'Atenção!',
                text: 'Registro alterado com sucesso',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })

              // Atualiza items do menu,
              this.updateUserData()
              this.$root.$emit('atualizarItemsMenu')
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      marcarDesmarcarHandler() {
        this.CSimulador()
      },
    },
  }
</script>

<style scoped>
  .text-lg {
    font-size: 1.3rem;
  }
</style>
