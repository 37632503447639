<template>
  <div class="cardRelatorio">
    <b-row>
      <b-col cols="2">
        <b-card-header
          header="Tomador"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.CadastroTomador" @change="marcarDesmarcarHandler('CadastroTomador')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.CadastroTomador"
                v-model="optionsLocal.CadastroTomadorConsulta"
                @change="marcarDesmarcarHandler('CadastroTomadorConsulta')"
                >Consultar</b-form-checkbox
              >

              <b-form-group> </b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.CadastroTomador || !optionsLocal.CadastroTomadorConsulta"
                v-model="optionsLocal.CadastroTomadorEditar"
                >Editar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroTomadorCriarNovo"
                :disabled="!optionsLocal.CadastroTomador || !optionsLocal.CadastroTomadorConsulta"
                >Criar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroTomadorExcluir"
                :disabled="!optionsLocal.CadastroTomador || !optionsLocal.CadastroTomadorConsulta"
                >Excluir</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Orgão"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.CadastroOrgao" @change="marcarDesmarcarHandler('CadastroOrgao')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroOrgaoConsulta"
                :disabled="!optionsLocal.CadastroOrgao"
                @change="marcarDesmarcarHandler('CadastroOrgaoConsulta')"
                >Consultar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroOrgaoEditar"
                :disabled="!optionsLocal.CadastroOrgao || !optionsLocal.CadastroOrgaoConsulta"
                >Editar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroOrgaoCriarNovo"
                :disabled="!optionsLocal.CadastroOrgao || !optionsLocal.CadastroOrgaoConsulta"
                >Criar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroOrgaoExcluir"
                :disabled="!optionsLocal.CadastroOrgao || !optionsLocal.CadastroOrgaoConsulta"
                >Excluir</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Grupo Folha"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox
                switches
                v-model="optionsLocal.CadastroGrupoFolha"
                @change="marcarDesmarcarHandler('CadastroGrupoFolha')"
              >
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroGrupoFolhaConsulta"
                :disabled="!optionsLocal.CadastroGrupoFolha"
                @change="marcarDesmarcarHandler('CadastroGrupoFolhaConsulta')"
                >Consultar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroGrupoFolhaEditar"
                :disabled="!optionsLocal.CadastroGrupoFolha || !optionsLocal.CadastroGrupoFolhaConsulta"
                >Editar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroGrupoFolhaCriarNovo"
                :disabled="!optionsLocal.CadastroGrupoFolha || !optionsLocal.CadastroGrupoFolhaConsulta"
                >Criar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="optionsLocal.CadastroGrupoFolhaExcluir"
                :disabled="!optionsLocal.CadastroGrupoFolha || !optionsLocal.CadastroGrupoFolhaConsulta"
                >Excluir</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-1" @click.prevent="authESalve">
          Salvar
        </b-button>
      </b-col>
    </b-row>
    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </div>
</template>

<script>
  import {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BButton,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BCardText,
  } from 'bootstrap-vue'
  import useJwt from '@/auth/jwt/useJwt'
  import Ripple from 'vue-ripple-directive'

  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'

  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import cryptUserData from '@/@core/auth/utils/Crypt-User-Data'

  export default {
    components: {
      BCard,
      BTable,
      BCardBody,
      BCardTitle,
      BCardSubTitle,
      BFormCheckbox,
      BButton,
      BForm,
      BRow,
      BCol,
      BFormGroup,
      BCardText,
      AutenticaSenha,
      ToastificationContent,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        cryptUserData,
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      }
    },
    mounted() {},
    methods: {
      CCadastroTomador() {
        const value = this.optionsLocal.CadastroTomador
        this.optionsLocal = {
          ...this.optionsLocal,
          CadastroTomadorConsulta: value,
          CadastroTomadorEditar: value,
          CadastroTomadorCriarNovo: value,
          CadastroTomadorExcluir: value,
        }
      },
      CCadastroOrgao() {
        const value = this.optionsLocal.CadastroOrgao
        this.optionsLocal = {
          ...this.optionsLocal,
          CadastroOrgaoConsulta: value,
          CadastroOrgaoEditar: value,
          CadastroOrgaoCriarNovo: value,
          CadastroOrgaoExcluir: value,
        }
      },
      CCadastroGrupoFolha() {
        const value = this.optionsLocal.CadastroGrupoFolha
        this.optionsLocal = {
          ...this.optionsLocal,
          CadastroGrupoFolhaConsulta: value,
          CadastroGrupoFolhaEditar: value,
          CadastroGrupoFolhaCriarNovo: value,
          CadastroGrupoFolhaExcluir: value,
        }
      },
      authESalve() {
        this.optionsLocal.mode = 'authESalve'
        this.$refs['modal-auth-senha'].show()
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.optionsLocal.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
      salvar() {
        const parametro = {
          ...this.optionsLocal,
        }

        useJwt
          .update(`grupo-acesso/putGrupoCadastro`, this.optionsLocal.id, parametro)
          .then((response) => {
            const userDataLocal = this.$jwtDecode(localStorage.getItem('userData')).userData
            userDataLocal.grupoAcesso = response.data
            this.cryptUserData(userDataLocal)

            this.$swal({
              title: 'Atenção!',
              text: 'Registro alterado com sucesso',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch((error) => {
            console.error(error)
          })
      },
      marcarDesmarcarHandler(tipo) {
        if (tipo == 'CadastroTomador') {
          this.CCadastroTomador()
        }
        if (tipo == 'CadastroTomadorConsulta') {
          const value = this.optionsLocal.CadastroTomadorConsulta
          this.optionsLocal = {
            ...this.optionsLocal,
            CadastroTomadorEditar: value,
            CadastroTomadorCriarNovo: value,
            CadastroTomadorExcluir: value,
          }
        }
        if (tipo == 'CadastroOrgao') {
          this.CCadastroOrgao()
        }
        if (tipo == 'CadastroOrgaoConsulta') {
          const value = this.optionsLocal.CadastroOrgaoConsulta
          this.optionsLocal = {
            ...this.optionsLocal,
            CadastroOrgaoEditar: value,
            CadastroOrgaoCriarNovo: value,
            CadastroOrgaoExcluir: value,
          }
        }
        if (tipo == 'CadastroGrupoFolha') {
          this.CCadastroGrupoFolha()
        }
        if (tipo == 'CadastroGrupoFolhaConsulta') {
          const value = this.optionsLocal.CadastroGrupoFolhaConsulta
          this.optionsLocal = {
            ...this.optionsLocal,
            CadastroGrupoFolhaEditar: value,
            CadastroGrupoFolhaCriarNovo: value,
            CadastroGrupoFolhaExcluir: value,
          }
        }
      },
    },
  }
</script>

<style scoped>
  .text-lg {
    font-size: 1.3rem;
  }
</style>
