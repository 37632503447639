<template>
  <b-tabs>
    <b-card-text class="mb-1" style="color: red">
      Por padrão, um grupo vem com todas as permissões. Logo, retire as permissões não desejadas para o grupo de acesso:
      <strong>{{ this.options.nome }}</strong>
    </b-card-text>

    <b-tab active>
      <template #title>
        <feather-icon icon="UserPlusIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Cadastro</span>
      </template>
      <GrupoAcessoCadastro v-if="options" :general-data="options" />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="ShoppingBagIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Simulador de proposta</span>
      </template>
      <GrupoAcessoSimulador v-if="options" :general-data="options" />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="SlidersIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Contrato</span>
      </template>
      <GrupoAcessoContrato v-if="options" :general-data="options" />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="DollarSignIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Financeiro</span>
      </template>
      <GrupoAcessoFinanceiro v-if="options" :general-data="options" />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="AlignJustifyIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Renegociacao</span>
      </template>
      <GrupoAcessoRenegociacao v-if="options" :general-data="options" />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="BarChartIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Relatório</span>
      </template>
      <GrupoAcessoRelatorio v-if="options" :general-data="options" />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="MonitorIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Dashboards</span>
      </template>
      <GrupoAcessoDashboards v-if="options" :general-data="options" />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="HardDriveIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Importar/Exportar</span>
      </template>
      <GrupoAcessoImportarExportar v-if="options" :general-data="options" />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="SettingsIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Configurações</span>
      </template>
      <GrupoAcessoConfiguracoes v-if="options" :general-data="options" />
    </b-tab>
  </b-tabs>
</template>

<script>
  import GrupoAcessoCadastro from '../Cadastro/Cadastro.vue'
  import GrupoAcessoSimulador from '../Simulador/Simulador.vue'
  import GrupoAcessoContrato from '../Contrato/Contrato.vue'
  import GrupoAcessoRelatorio from '../Relatorio/Relatorio.vue'
  import GrupoAcessoImportarExportar from '../ImportarExportar/ImportarExportar.vue'
  import GrupoAcessoConfiguracoes from '../Configuracoes/Configuracoes.vue'
  import GrupoAcessoFinanceiro from '../Financeiro/Financeiro.vue'
  import GrupoAcessoDashboards from '../Dashboards/Dashboards.vue'
  import GrupoAcessoRenegociacao from '../Renegociacao/Renegociacao.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { options } from '@coders-tm/vue-number-format'

  export default {
    components: {
      GrupoAcessoCadastro,
      GrupoAcessoSimulador,
      GrupoAcessoContrato,
      GrupoAcessoRelatorio,
      GrupoAcessoImportarExportar,
      GrupoAcessoConfiguracoes,
      GrupoAcessoFinanceiro,
      GrupoAcessoDashboards,
      GrupoAcessoRenegociacao,
      ToastificationContent,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        options: JSON.parse(JSON.stringify(this.generalData)),
      }
    },
  }
</script>

<style></style>
./GrupoDeAcesso.vue
