//@ts-check
/**
 * Represents a role in a legal document.
 * @typedef {'tomador' | 'testemunha' | 'gestor'} AssinaturaCargoTexto
 */

/**
 * Represents a role in a legal document.
 * @typedef {'Tomador' | 'Testemunha' | 'Gestor'} AssinaturaCargoValor
 */

/**
 * An array of signature roles.
 * @type {{ value: AssinaturaCargoTexto, text: AssinaturaCargoValor }[]}
 */
export const assinaturaCargos = [
  { value: 'testemunha', text: 'Testemunha' },
  { value: 'gestor', text: 'Gestor' },
]
