<template>
  <div class="cardRelatorio">
    <b-row>
      <b-col cols="2">
        <b-card-header
          header="Sistema"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistema" @change="marcarDesmarcarHandler('Sistema')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaEditar">Editar</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Notificações"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox
                v-model="optionsLocal.ConfiguracoesSistemaNotificacoesAcessar"
                @change="marcarDesmarcarHandler('Notificações')"
              >
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaNotificacoesEditar">Editar</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Composição de taxas"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox
                v-model="optionsLocal.ConfiguracoesSistemaCompTaxasAcessar"
                @change="marcarDesmarcarHandler('Composição de taxas')"
              >
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaCompTaxasEditar">Editar</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Parâmetros de Arrecadação"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox
                v-model="optionsLocal.ConfiguracoesSistemaParametroArrecadacaoAcessar"
                @change="marcarDesmarcarHandler('Parâmetros de Arrecadação')"
              >
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaParametroArrecadacaoEditar">Editar</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Critérios de Elegibiliade"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox
                v-model="optionsLocal.ConfiguracoesSistemaCriterioElegibiliadeAcessar"
                @change="marcarDesmarcarHandler('Critérios de Elegibiliade')"
              >
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaCriterioElegibiliadeEditar">Editar</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Configurações de Perfil"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox
                v-model="optionsLocal.ConfiguracoesSistemaPerfilAcessar"
                @change="marcarDesmarcarHandler('Configurações de Perfil')"
              >
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaPerfilEditar">Editar</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Gerenciamento de Log"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox
                v-model="optionsLocal.ConfiguracoesSistemaGerenciamentoLogAcessar"
                @change="marcarDesmarcarHandler('Gerenciamento de Log')"
              >
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaGerenciamentoLogEditar">Editar</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Personalização"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox
                v-model="optionsLocal.ConfiguracoesSistemaPersonalizacao"
                @change="marcarDesmarcarHandler('Personalização')"
              >
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaPersonalizacaoAparencia"
                >Personalizar aparencia</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaPersonalizacaoInformacao"
                >Personalizar informações</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Indice de Referência"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox
                v-model="optionsLocal.ConfiguracoesSistemaIndiceRef"
                @change="marcarDesmarcarHandler('Indice de Referência')"
              >
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaIndiceRefConsultar">Consultar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaIndiceRefEditar">Editar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaIndiceRefCriarNovo">Criar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaIndiceRefExcluir">Excluir</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Modelos"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesModelos" @change="marcarDesmarcarHandler('Modelos')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesModelosConsultar">Consultar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesModelosEditar">Editar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesModelosCriarNovo">Criar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesModelosExcluir">Excluir</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Grupo de Acesso"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesWorkflor" @change="marcarDesmarcarHandler('Workflow')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesWorkflowConsultar">Consultar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesWorkflowEditar">Editar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesWorkflowCriarNovo">Criar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesWorkflowExcluir">Excluir</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Usuario"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.CadastroUsuario" @change="marcarDesmarcarHandler('Usuario')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.CadastroUsuarioConsulta">Consultar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.CadastroUsuarioEditar">Editar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.CadastroUsuarioCriarNovo">Criar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.CadastroUsuarioExcluir">Excluir</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Unidade Gestora"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox
                v-model="optionsLocal.CadastroUnidadeGestora"
                @change="marcarDesmarcarHandler('Unidade Gestora')"
              >
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.CadastroUnidadeGestoraConsulta">Consultar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.CadastroUnidadeGestoraEditar">Editar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.CadastroUnidadeGestoraCriarNovo">Criar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.CadastroUnidadeGestoraExcluir">Excluir</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Restrições"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox
                v-model="optionsLocal.ConfiguracoesSistemaRestricao"
                @change="marcarDesmarcarHandler('Restrições')"
              >
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaRestricaoLimitadorIdade"
                >Limitador de Idade</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaRestricaoAssDigital"
                >Assinatura Digital</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaRestricaoRegularidadePrevidenciaria"
                >Regularidade Previdenciária</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaRestricaoPortabilidade">Portabilidade</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaRestricaoLimiteAlocacao"
                >Limite de Alocacao</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaRestricaoLimiteParcelas"
                >Limite de Parcelas</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaRestricaoPercentualMargem"
                >Percentual de Margem</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaRestricaoCapag">CAPAG</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaRestricaoCarencia">Carência</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistemaRestricaoAliquotasIOF">Alíquotas IOF</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-1" @click.prevent="authESalve">
          Salvar
        </b-button>
      </b-col>
    </b-row>
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </div>
</template>

<script>
  import {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BButton,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BCardText,
  } from 'bootstrap-vue'
  import useJwt from '@/auth/jwt/useJwt'
  import Ripple from 'vue-ripple-directive'

  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'

  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      BCard,
      BTable,
      BCardBody,
      BCardTitle,
      BCardSubTitle,
      BFormCheckbox,
      BButton,
      BForm,
      BRow,
      BCol,
      BFormGroup,
      BCardText,
      AutenticaSenha,
      ToastificationContent,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      }
    },
    methods: {
      CConfiguracoesPersonalizacao() {
        const value = this.optionsLocal.ConfiguracoesSistemaPersonalizacao
        this.optionsLocal = {
          ...this.optionsLocal,
          ConfiguracoesSistemaPersonalizacaoAparencia: value,
          ConfiguracoesSistemaPersonalizacaoInformacao: value,
        }
      },
      CConfiguracoesIndiceRef() {
        const value = this.optionsLocal.ConfiguracoesSistemaIndiceRef
        this.optionsLocal = {
          ...this.optionsLocal,
          ConfiguracoesSistemaIndiceRefConsultar: value,
          ConfiguracoesSistemaIndiceRefEditar: value,
          ConfiguracoesSistemaIndiceRefCriarNovo: value,
          ConfiguracoesSistemaIndiceRefExcluir: value,
        }
      },
      CConfiguracoesModelos() {
        const value = this.optionsLocal.ConfiguracoesModelos
        this.optionsLocal = {
          ...this.optionsLocal,
          ConfiguracoesModelosConsultar: value,
          ConfiguracoesModelosCriarNovo: value,
          ConfiguracoesModelosEditar: value,
          ConfiguracoesModelosExcluir: value,
        }
      },
      CConfiguracoesWorkflor() {
        const value = this.optionsLocal.ConfiguracoesWorkflor
        this.optionsLocal = {
          ...this.optionsLocal,
          ConfiguracoesWorkflowConsultar: value,
          ConfiguracoesWorkflowCriarNovo: value,
          ConfiguracoesWorkflowEditar: value,
          ConfiguracoesWorkflowExcluir: value,
        }
      },
      CCadastroUsuario() {
        const value = this.optionsLocal.CadastroUsuario
        this.optionsLocal = {
          ...this.optionsLocal,
          CadastroUsuarioConsulta: value,
          CadastroUsuarioCriarNovo: value,
          CadastroUsuarioEditar: value,
          CadastroUsuarioExcluir: value,
        }
      },
      CCadastroUnidadeGestora() {
        const value = this.optionsLocal.CadastroUnidadeGestora
        this.optionsLocal = {
          ...this.optionsLocal,
          CadastroUnidadeGestoraConsulta: value,
          CadastroUnidadeGestoraCriarNovo: value,
          CadastroUnidadeGestoraEditar: value,
          CadastroUnidadeGestoraExcluir: value,
        }
      },
      CConfiguracoesSistema() {
        const value = this.optionsLocal.ConfiguracoesSistema
        this.optionsLocal = {
          ...this.optionsLocal,
          ConfiguracoesSistemaEditar: value,
          ConfiguracoesSistema: value,
        }
      },

      CNotificaoes() {
        const value = this.optionsLocal.ConfiguracoesSistemaNotificacoesAcessar
        this.optionsLocal = {
          ...this.optionsLocal,
          ConfiguracoesSistemaNotificacoesAcessar: value,
          ConfiguracoesSistemaNotificacoesEditar: value,
        }
      },
      CComposicaoTaxas() {
        const value = this.optionsLocal.ConfiguracoesSistemaCompTaxasAcessar
        this.optionsLocal = {
          ...this.optionsLocal,
          ConfiguracoesSistemaCompTaxasAcessar: value,
          ConfiguracoesSistemaCompTaxasEditar: value,
        }
      },
      CParametrosArrecadacao() {
        const value = this.optionsLocal.ConfiguracoesSistemaParametroArrecadacaoAcessar
        this.optionsLocal = {
          ...this.optionsLocal,
          ConfiguracoesSistemaParametroArrecadacaoAcessar: value,
          ConfiguracoesSistemaParametroArrecadacaoEditar: value,
        }
      },
      CCriterioElegibilidade() {
        const value = this.optionsLocal.ConfiguracoesSistemaCriterioElegibiliadeAcessar
        this.optionsLocal = {
          ...this.optionsLocal,
          ConfiguracoesSistemaCriterioElegibiliadeAcessar: value,
          ConfiguracoesSistemaCriterioElegibiliadeEditar: value,
        }
      },
      CConfiguracoesPerfil() {
        const value = this.optionsLocal.ConfiguracoesSistemaPerfilAcessar
        this.optionsLocal = {
          ...this.optionsLocal,
          ConfiguracoesSistemaPerfilAcessar: value,
          ConfiguracoesSistemaPerfilEditar: value,
        }
      },
      CGerenciamentoLog() {
        const value = this.optionsLocal.ConfiguracoesSistemaGerenciamentoLogAcessar
        this.optionsLocal = {
          ...this.optionsLocal,
          ConfiguracoesSistemaGerenciamentoLogAcessar: value,
          ConfiguracoesSistemaGerenciamentoLogEditar: value,
        }
      },

      CConfiguracoesRestricao() {
        const value = this.optionsLocal.ConfiguracoesSistemaRestricao
        this.optionsLocal = {
          ...this.optionsLocal,
          ConfiguracoesSistemaRestricaoLimitadorIdade: value,
          ConfiguracoesSistemaRestricaoAssDigital: value,
          ConfiguracoesSistemaRestricaoRegularidadePrevidenciaria: value,
          ConfiguracoesSistemaRestricaoPortabilidade: value,
          ConfiguracoesSistemaRestricaoLimiteAlocacao: value,
          ConfiguracoesSistemaRestricaoLimiteParcelas: value,
          ConfiguracoesSistemaRestricaoPercentualMargem: value,
          ConfiguracoesSistemaRestricaoCapag: value,
          ConfiguracoesSistemaRestricaoCarencia: value,
          ConfiguracoesSistemaRestricaoAliquotasIOF: value,
        }
      },
      authESalve() {
        this.optionsLocal.mode = 'authESalve'
        this.$refs['modal-auth-senha'].show()
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.optionsLocal.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
      salvar() {
        if (this.optionsLocal.id == undefined) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erro: O instituto a ser configurado não está sendo identificado, reeinicie o sistema. ',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
        } else {
          const aux = {
            ...this.optionsLocal,
          }
          useJwt
            .update(`grupo-acesso/putGrupoConfiguracoes`, this.optionsLocal.id, aux)
            .then((response) => {
              this.$swal({
                title: 'Atenção!',
                text: 'Registro alterado com sucesso',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      marcarDesmarcarHandler(tipo) {
        if (tipo == 'Sistema') {
          this.CConfiguracoesSistema()
        }
        if (tipo == 'Notificações') {
          this.CNotificaoes()
        }
        if (tipo == 'Composição de taxas') {
          this.CComposicaoTaxas()
        }
        if (tipo == 'Parâmetros de Arrecadação') {
          this.CParametrosArrecadacao()
        }
        if (tipo == 'Critérios de Elegibiliade') {
          this.CCriterioElegibilidade()
        }
        if (tipo == 'Configurações de Perfil') {
          this.CConfiguracoesPerfil()
        }
        if (tipo == 'Gerenciamento de Log') {
          this.CGerenciamentoLog()
        }
        if (tipo == 'Personalização') {
          this.CConfiguracoesPersonalizacao()
        }
        if (tipo == 'Indice de Referência') {
          this.CConfiguracoesIndiceRef()
        }
        if (tipo == 'Modelos') {
          this.CConfiguracoesModelos()
        }
        if (tipo == 'Workflow') {
          this.CConfiguracoesWorkflor()
        }
        if (tipo == 'Usuario') {
          this.CCadastroUsuario()
        }
        if (tipo == 'Unidade Gestora') {
          this.CCadastroUnidadeGestora()
        }
        if (tipo == 'Restrições') {
          this.CConfiguracoesRestricao()
        }
      },
    },
  }
</script>

<style scoped>
  .text-lg {
    font-size: 1.3rem;
  }
</style>
