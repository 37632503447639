<template>
  <div class="cardRelatorio">
    <b-row>
      <b-col cols="2">
        <b-card-header
          header="Pagamento"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.FinanceiroPagamento" @change="marcarDesmarcarHandler('Pagamento')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroPagamentoEditar">Editar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroPagamentoImportarExportar">Importar/Exportar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroPagamentoGerarRemessa">Gerar Remessa</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroPagamentoImportarRetorno">Importar Retorno</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Remessa Boletos"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox
                v-model="optionsLocal.FinanceiroRemessaBoleto"
                @change="marcarDesmarcarHandler('RemessaBoletos')"
              >
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroRemessaBoletoEditar">Editar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroRemessaBoletoImportarExportar"
                >Importar/Exportar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroRemessaBoletoGerarRemessa">Gerar Remessa</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroRemessaBoletoGerarRetorno">Gerar Retorno</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Lançamentos"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.FinanceiroLancamento" @change="marcarDesmarcarHandler('Lancamentos')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroLancamentoEditar">Editar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroLancamentoGerar">Gerar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroLancamentoRecalcularParcelas"
                >Recálculo de Parcelas - Recalcular</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroLancamentoEnviarLancamento"
                >Recálculo de Parcelas - Enviar para lançamentos</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroLancamentoNaoDescontadoIntegracao"
                >Não descontados - Integração</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroLancamentoNaoDescontadoBaixar"
                >Não descontados - Baixar</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroLancamentoNaoDescontadoExtrato"
                >Não descontados - Extrato de parcelas</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroLancamentoDescontadoExportar"
                >Descontadas - Exportar</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Arrecadação"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.FinanceiroArrecadacao" @change="marcarDesmarcarHandler('Arrecadacao')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroArrecadacaoEditar">Editar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroArrecadacaoGerar">Gerar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroArrecadacaoGerarGuia">Gerar Guia</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroArrecadacaoGerarBoleto">Gerar Boleto</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroArrecadacaoConfirmarPagamento"
                >Confirmar Pagamento</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-1" @click.prevent="authESalve">
          Salvar
        </b-button>
      </b-col>
    </b-row>
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import Ripple from 'vue-ripple-directive'

  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'

  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import cryptUserData from '@/@core/auth/utils/Crypt-User-Data'

  export default {
    components: {
      AutenticaSenha,
      ToastificationContent,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        cryptUserData,
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      }
    },
    methods: {
      CFinanceiroPagamento() {
        const value = this.optionsLocal.FinanceiroPagamento
        this.optionsLocal = {
          ...this.optionsLocal,
          FinanceiroPagamentoEditar: value,
          FinanceiroPagamentoImportarExportar: value,
          FinanceiroPagamentoGerarRemessa: value,
          FinanceiroPagamentoImportarRetorno: value,
        }
      },
      CFinanceiroRemessaBoleto() {
        const value = this.optionsLocal.FinanceiroRemessaBoleto
        this.optionsLocal = {
          ...this.optionsLocal,
          FinanceiroRemessaBoletoEditar: value,
          FinanceiroRemessaBoletoImportarExportar: value,
          FinanceiroRemessaBoletoGerarRemessa: value,
          FinanceiroRemessaBoletoGerarRetorno: value,
        }
      },
      CFinanceiroLancamento() {
        const value = this.optionsLocal.FinanceiroLancamento
        this.optionsLocal = {
          ...this.optionsLocal,
          FinanceiroLancamentoEditar: value,
          FinanceiroLancamentoGerar: value,
          FinanceiroLancamentoRecalcularParcelas: value,
          FinanceiroLancamentoEnviarLancamento: value,
          FinanceiroLancamentoNaoDescontadoIntegracao: value,
          FinanceiroLancamentoNaoDescontadoBaixar: value,
          FinanceiroLancamentoNaoDescontadoExtrato: value,
          FinanceiroLancamentoDescontadoExportar: value,
        }
      },
      CFinanceiroArrecadacao() {
        const value = this.optionsLocal.FinanceiroArrecadacao
        this.optionsLocal = {
          ...this.optionsLocal,
          FinanceiroArrecadacaoEditar: value,
          FinanceiroArrecadacaoGerar: value,
          FinanceiroArrecadacaoGerarGuia: value,
          FinanceiroArrecadacaoGerarBoleto: value,
          FinanceiroArrecadacaoConfirmarPagamento: value,
        }
      },
      authESalve() {
        this.optionsLocal.mode = 'authESalve'
        this.$refs['modal-auth-senha'].show()
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.optionsLocal.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
      salvar() {
        const parametro = {
          ...this.optionsLocal,
        }

        useJwt
            .update(`grupo-acesso/putGrupoFinanceiro`, this.optionsLocal.id, parametro)
            .then((response) => {
            const userDataLocal = this.$jwtDecode(localStorage.getItem('userData')).userData
            userDataLocal.grupoAcesso = response.data
            this.cryptUserData(userDataLocal)

            this.$swal({
              title: 'Atenção!',
              text: 'Registro alterado com sucesso',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch((error) => {
            console.error(error)
          })
      },
      marcarDesmarcarHandler(tipo) {
        switch (tipo) {
          case 'Pagamento':
            this.CFinanceiroPagamento()
            break
          case 'RemessaBoletos':
            this.CFinanceiroRemessaBoleto()
            break
          case 'Lancamentos':
            this.CFinanceiroLancamento()
            break
          case 'Arrecadacao':
            this.CFinanceiroArrecadacao()
            break
        }
      },
    },
  }
</script>

<style scoped>
  .text-lg {
    font-size: 1.3rem;
  }
</style>
