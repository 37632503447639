<template>
  <div class="cardRelatorio">
    <b-row>
      <b-col cols="2">
        <b-card-header
          header="Cadastro"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.RelatorioCadastro" @change="marcarDesmarcarHandler('RelatorioCadastro')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.RelatorioCadastro" v-model="optionsLocal.RelatorioCadastroTomadores"
                >Tomadores</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.RelatorioCadastro" v-model="optionsLocal.RelatorioCadastroUsuarios"
                >Usuarios</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Simulador"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.RelatorioSimulador" @change="marcarDesmarcarHandler('RelatorioSimulador')"
                >Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.RelatorioSimulador" v-model="optionsLocal.RelatorioSimuladorProposta"
                >Proposta</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.RelatorioSimulador" v-model="optionsLocal.RelatorioSimuladorExterno"
                >Externo</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Contrato"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.RelatorioContrato" @change="marcarDesmarcarHandler('RelatorioContrato')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.RelatorioContrato" v-model="optionsLocal.RelatorioContratoContratos"
                >Contratos</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.RelatorioContrato" v-model="optionsLocal.RelatorioContratoSaldoDevedor"
                >Saldo Devedor</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card-header
          header="Financeiro"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox
                v-model="optionsLocal.RelatorioFinanceiro"
                @change="marcarDesmarcarHandler('RelatorioFinanceiro')"
              >
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.RelatorioFinanceiro"
                v-model="optionsLocal.RelatorioFinanceiroLancamento"
                >Lançamentos</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.RelatorioFinanceiro"
                v-model="optionsLocal.RelatorioFinanceiroArrecadacao"
                >Arrecadação</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.RelatorioFinanceiro"
                v-model="optionsLocal.RelatorioFinanceiroTaxasParcelas"
                >Taxas e parcelas</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.RelatorioFinanceiro" v-model="optionsLocal.RelatorioFinanceiroIOF"
                >IOF</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.RelatorioFinanceiro"
                v-model="optionsLocal.RelatorioFinanceiroCapitalAplicado"
                >Capital Aplicado x Rentabilidade</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.RelatorioFinanceiro" v-model="optionsLocal.RelatorioFinanceiroContabil"
                >Contábil</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                :disabled="!optionsLocal.RelatorioFinanceiro"
                v-model="optionsLocal.RelatorioFinanceiroParcelasAntecipadas"
                >Parcelas Antecipadas</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox :disabled="!optionsLocal.RelatorioFinanceiro" v-model="optionsLocal.RelatorioFinanceiroDAIR"
                >DAIR</b-form-checkbox
              >
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-1" @click.prevent="authESalve">
          Salvar
        </b-button>
      </b-col>
    </b-row>
    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </div>
</template>

<script>
  import {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BButton,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BCardText,
  } from 'bootstrap-vue'
  import useJwt from '@/auth/jwt/useJwt'
  import Ripple from 'vue-ripple-directive'

  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'

  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import RelatorioContratoContratos from '@/views/pages/relatorio/relatorioContratos/relatorioContratoContratos.vue'

  export default {
    components: {
      BCard,
      BTable,
      BCardBody,
      BCardTitle,
      BCardSubTitle,
      BFormCheckbox,
      BButton,
      BForm,
      BRow,
      BCol,
      BFormGroup,
      BCardText,
      AutenticaSenha,
      ToastificationContent,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      }
    },
    methods: {
      CCadastro() {
        const value = this.optionsLocal.RelatorioCadastro
        this.optionsLocal = {
          ...this.optionsLocal,
          RelatorioCadastroTomadores: value,
          RelatorioCadastroUsuarios: value,
        }
      },
      CSimulador() {
        const value = this.optionsLocal.RelatorioSimulador
        this.optionsLocal = {
          ...this.optionsLocal,
          RelatorioSimuladorProposta: value,
          RelatorioSimuladorExterno: value,
        }
      },
      CContrato() {
        const value = this.optionsLocal.RelatorioContrato
        this.optionsLocal = {
          ...this.optionsLocal,
          RelatorioContratoContratos: value,
          RelatorioContratoSaldoDevedor: value,
        }
      },
      CFinanceiro() {
        const value = this.optionsLocal.RelatorioFinanceiro
        this.optionsLocal = {
          ...this.optionsLocal,
          RelatorioFinanceiroExportar: value,
          RelatorioFinanceiroLancamento: value,
          RelatorioFinanceiroArrecadacao: value,
          RelatorioFinanceiroTaxasParcelas: value,
          RelatorioFinanceiroIOF: value,
          RelatorioFinanceiroCapitalAplicado: value,
          RelatorioFinanceiroContabil: value,
          RelatorioFinanceiroParcelasAntecipadas: value,
          RelatorioFinanceiroDAIR: value,
        }
      },
      authESalve() {
        this.optionsLocal.mode = 'authESalve'
        this.$refs['modal-auth-senha'].show()
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.optionsLocal.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
      salvar() {
        if (this.optionsLocal.id == undefined) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erro: O instituto a ser configurado não está sendo identificado, reeinicie o sistema. ',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
        } else {
          const aux = {
            ...this.optionsLocal,
          }
          useJwt
            .update(`grupo-acesso/putGrupoRelatorio`, this.optionsLocal.id, aux)
            .then((response) => {
              this.$swal({
                title: 'Atenção!',
                text: 'Registro alterado com sucesso',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      marcarDesmarcarHandler(tipo) {
        if (tipo == 'RelatorioCadastro') {
          this.CCadastro()
        }
        if (tipo == 'RelatorioSimulador') {
          this.CSimulador()
        }
        if (tipo == 'RelatorioContrato') {
          this.CContrato()
        }
        if (tipo == 'RelatorioFinanceiro') {
          this.CFinanceiro()
        }
      },
    },
  }
</script>

<style scoped>
  .text-lg {
    font-size: 1.3rem;
  }
</style>
