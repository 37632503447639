var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('validation-observer',{ref:"formPoliticaSenhas"},[_c('b-form',[_c('b-row',{staticClass:"mb-2",attrs:{"md":"12"}},[_c('b-col',{attrs:{"md":"12"}},[_c('h2',[_vm._v("Política de senhas")])]),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Quantidade de caracteres","label-for":"senhaQuantidadeCaracteres"}},[_c('validation-provider',{attrs:{"name":"Quantidade de caracteres","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"senhaQuantidadeCaracteres","name":"senhaQuantidadeCaracteres","options":_vm.quantidadeCaracteresOpcoes,"placeholder":"Quantidade caracteres"},model:{value:(_vm.optionsLocal.senhaQuantidadeCaractere),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "senhaQuantidadeCaractere", $$v)},expression:"optionsLocal.senhaQuantidadeCaractere"}}),_c('b-tooltip',{attrs:{"placement":"bottom","target":"senhaQuantidadeCaracteres","noninteractive":""}},[_vm._v("Quantidade mínima de caracteres que será utilizada na senha")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"auto"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Caso habilitado, a senha deverá conter um número'),expression:"'Caso habilitado, a senha deverá conter um número'",modifiers:{"hover":true,"bottom":true}}],attrs:{"label":"Deverá conter número?","label-for":"senhaNumero"}},[_c('validation-provider',{attrs:{"name":"Habilita Biometria Facial"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"checked":"false","name":"senhaNumero","id":"senhaNumero","switch":"","inline":""},model:{value:(_vm.optionsLocal.senhaNumero),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "senhaNumero", $$v)},expression:"optionsLocal.senhaNumero"}},[_vm._v(" "+_vm._s(_vm.optionsLocal.senhaNumero ? 'Sim' : 'Não')+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"auto"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Caso habilitado, a senha deverá conter um caracter especial'),expression:"'Caso habilitado, a senha deverá conter um caracter especial'",modifiers:{"hover":true,"bottom":true}}],attrs:{"label":"Caractere especial?","label-for":"senhaCaractereEspecial"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"checked":"false","name":"senhaCaractereEspecial","id":"senhaCaractereEspecial","switch":"","inline":""},model:{value:(_vm.optionsLocal.senhaCaractereEspecial),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "senhaCaractereEspecial", $$v)},expression:"optionsLocal.senhaCaractereEspecial"}},[_vm._v(" "+_vm._s(_vm.optionsLocal.senhaCaractereEspecial ? 'Sim' : 'Não')+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"auto"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Caso habilitado, a senha deverá conter uma letra maiúscula'),expression:"'Caso habilitado, a senha deverá conter uma letra maiúscula'",modifiers:{"hover":true,"bottom":true}}],attrs:{"label":"Letra maiúscula?","label-for":"senhaLetraMaiuscula"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"checked":"false","name":"senhaLetraMaiuscula","id":"senhaLetraMaiuscula","switch":"","inline":""},model:{value:(_vm.optionsLocal.senhaLetraMaiuscula),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "senhaLetraMaiuscula", $$v)},expression:"optionsLocal.senhaLetraMaiuscula"}},[_vm._v(" "+_vm._s(_vm.optionsLocal.senhaLetraMaiuscula ? 'Sim' : 'Não')+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"auto"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Caso habilitado, a senha deverá conter uma letra minúscula'),expression:"'Caso habilitado, a senha deverá conter uma letra minúscula'",modifiers:{"hover":true,"bottom":true}}],attrs:{"label":"Letra minúscula?","label-for":"senhaLetraMinuscula"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"checked":"false","name":"senhaLetraMinuscula","id":"senhaLetraMinuscula","switch":"","inline":""},model:{value:(_vm.optionsLocal.senhaLetraMinuscula),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "senhaLetraMinuscula", $$v)},expression:"optionsLocal.senhaLetraMinuscula"}},[_vm._v(" "+_vm._s(_vm.optionsLocal.senhaLetraMinuscula ? 'Sim' : 'Não')+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticStyle:{"margin-left":"0.1rem","margin-top":"5rem"},attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",staticStyle:{"width":"160px"},attrs:{"id":"savebutton","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.authESalve.apply(null, arguments)}}},[_vm._v(" "+_vm._s(!_vm.isBusy ? 'Salvar' : '')+" "),(_vm.isBusy)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",staticStyle:{"width":"160px"},attrs:{"id":"clearbutton","variant":"outline-secondary","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.limpar.apply(null, arguments)}}},[_vm._v(" Limpar ")])],1)],1)],1),_c('b-modal',{ref:"modal-auth-senha",attrs:{"id":"modal-auth-senha","centered":"","size":"sm","title":"Autenticação","hide-footer":""}},[_c('AutenticaSenha',{on:{"autenticarEvent":_vm.autenticarMethod}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }