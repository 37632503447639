<template>
  <div>
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="SmartphoneIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Aplicativo</span>
        </template>
        <ConfiguracoesAplicativo v-if="options" :general-data="options.ConfiguracaoAplicativo" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="HardDriveIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Gerenciamento de Log</span>
        </template>
        <ConfiguracoesGerenciamentoLog v-if="options" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="EditIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Grupos de Acesso</span>
        </template>
        <ConfiguracoesGruposDeAcesso v-if="options" :general-data="options" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="BellIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Notificações</span>
        </template>
        <ConfiguracoesNotificacoes v-if="options" :general-data="options" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="KeyIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Política de senhas</span>
        </template>
        <ConfiguracoesPoliticaSenha v-if="options" :general-data="options" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="UsersIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Usuários</span>
        </template>
        <ConfiguracoesUsuario v-if="options" :general-data="options" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import ConfiguracoesAplicativo from './Aplicativo/Aplicativo.vue'
  import ConfiguracoesGerenciamentoLog from './GerenciamentoDeLog/GerenciamentoLog.vue'
  import ConfiguracoesGruposDeAcesso from './GrupoDeAcesso/GrupoDeAcesso.vue'
  import ConfiguracoesNotificacoes from './Notificacoes/Notificacoes.vue'
  import ConfiguracoesPoliticaSenha from './PoliticaSenha/PoliticaSenha.vue'
  import ConfiguracoesUsuario from './Usuario/Usuario.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import useJwt from '@/auth/jwt/useJwt'
  import { required } from '@validations'

  export default {
    components: {
      ConfiguracoesAplicativo,
      ConfiguracoesGerenciamentoLog,
      ConfiguracoesGruposDeAcesso,
      ConfiguracoesNotificacoes,
      ConfiguracoesPoliticaSenha,
      ConfiguracoesUsuario,
      ValidationObserver,
      ValidationProvider,
      required,
    },
    data() {
      return {
        options: null,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        tipoIndice: '',
      }
    },
    mounted() {
      this.carregarGrid()
    },
    methods: {
      carregarGrid() {
        useJwt
          .get(`sistema`)
          .then((response) => {
            const resposta = {
              ...response.data,
              ConfiguracaoAplicativo: response.data.ConfiguracaoAplicativo,
              ConfiguracaoPoliticaDeSenha: response.data.ConfiguracaoPoliticaDeSenha,
            }
            this.options = resposta
          })
          .catch((error) => {
            console.error(error)
          })
      },
      atualizarIndice(tipoIndice) {
        this.$refs.ConfiguracoesSistemaComposicaoTaxa.atualizaIndice(tipoIndice)
      },
    },
  }
</script>
